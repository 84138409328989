// title: Lucky Paper Radio — A Podcast about Magic: the Gathering
// frameCardName: Arcane Denial
// frameCardSet: A25

import * as styles from './podcast.module.scss'

import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { Link, graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'

import PodcastLayout from 'src/components/podcast/PodcastLayout'
import { PlayerContextProvider } from 'src/components/podcast/PlayerContext'

interface Props extends PageProps<Queries.PodcastPageQuery> {
  pageContext: {
    url: string
  }
}

const PodcastPage: React.FC<Props> = (props) => {
  const { podcasts } = props.data.allMdx

  const sortedEpisodes = useMemo(() => {
    // Gatsby's sorting seems to not work completely consistently
    return sortBy(
      podcasts,

      (episode) => episode.frontmatter?.episodeOrder,
    ).reverse()
  }, [podcasts])

  return (
    <Layout
      {...props.pageContext}
      accentColor="#044AB7"
      includeNewsletterSignup
    >
      <PlayerContextProvider>
        <PodcastLayout>
          <div className={styles.episodes}>
            {sortedEpisodes.map((podcast) => {
              if (podcast.frontmatter == null) {
                return null
              }

              return (
                <Link
                  key={podcast.id}
                  to={`/podcast/${podcast.frontmatter.episodeSlug}/`}
                >
                  <figure className={styles.episodesEpisode}>
                    <div>
                      {podcast.frontmatter.episodeNumber ? (
                        <div className={styles.episodesEpisodeNumber}>
                          Episode {podcast.frontmatter.episodeNumber}
                        </div>
                      ) : (
                        <div className={styles.episodesEpisodeBonus}>
                          Bonus Episode
                        </div>
                      )}
                      <figcaption className={styles.episodesEpisodeTitle}>
                        {podcast.frontmatter.title}
                      </figcaption>
                      <div className={styles.episodesEpisodeDate}>
                        {podcast.frontmatter.date}
                      </div>
                    </div>
                    <div className={styles.episodesEpisodeDescription}>
                      {podcast.frontmatter.excerpt}
                    </div>
                    <div className={styles.episodesEpisodeListenLinkReminder}>
                      ▶&#xFE0E;
                    </div>
                  </figure>
                </Link>
              )
            })}
          </div>
        </PodcastLayout>
      </PlayerContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query PodcastPage {
    allMdx(
      filter: { fields: { type: { eq: "podcast-notes" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      podcasts: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
          episodeOrder
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Lucky Paper Radio — A Podcast about Magic: the Gathering',
        description:
          'A podcast about Magic: the Gathering and related topics, brought to you by Andy Mangold and Anthony Mattox.',
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        rssFeed: {
          title: 'Lucky Paper Radio',
          url: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        },
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'PodcastSeries',
        name: 'Lucky Paper Radio',
        description:
          'Lucky Paper Radio is a podcast about Magic: the Gathering hosted by lifelong friends Andy Mangold and Anthony Mattox. Focused primarily on sandbox formats like Cube, which are free of banlists, rotation, and net decking, the show is about the best parts of Magic and how to get the most out of the game.',
        url: props.pageContext.url,
        webFeed: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        genre: 'Games',
        startDate: '2020-07-05',
        creator: [
          {
            '@type': 'Person',
            name: 'Andy Mangold',
          },
          {
            '@type': 'Person',
            name: 'Anthony Mattox',
          },
        ],
      }}
    />
  )
}

export default PodcastPage
